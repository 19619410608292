
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { PessoaService } from "@/core/services/cadastros";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
 
@Component
export default class CfgEmpreendimento extends Vue {
    filter: {
      grupoId:number;
      empreendimentoId:number;
      unidadeId:number;
      empresaId:number;
      clienteId:number;
    } = {
      grupoId:0,
      empreendimentoId:0,
      unidadeId:0,
      empresaId:0,
      clienteId:0
    };

    valid = true; 
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    empreendimentos = [];
    listaGrupos = [];
    listaUnidades = [];
    pessoas = [];
    empresas = [];

    FiltrarUnidades() {
      new EmpreendimentoService().ListarUnidade(this.filter.grupoId).then(
        res => {
          this.listaUnidades = res.data
        }
      )
    }
 
    FiltrarGrupos() {
      new EmpreendimentoService().ListarGrupo(this.filter.empreendimentoId).then(
        res => {
          this.listaGrupos = res.data
        }
      )
    } 
     
    mounted() {
      new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        (res) => {
          this.empreendimentos = res.data.items
        }, 
      );

      new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        (res) => {
          this.empresas = res.data.items
        }, 
      );

      new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome, nomeCnpjCpf', '').then(
        res => {
          this.pessoas = res.data.items;
      }
    );
  }   

  Visualizar() {
    if(!this.filter.empreendimentoId && !this.filter.grupoId && !this.filter.unidadeId && !this.filter.clienteId && !this.filter.empresaId){
        this.$swal("Aviso", "Obrigatório preencher pelo menos um dos campos.", "warning");
        return true;
    }     
    let routeLink = this.$router.resolve({name: 'relFundoReserva',
      query:{
        empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : '',
        grupoId: this.filter.grupoId ? this.filter.grupoId.toString() : '',
        unidadeId: this.filter.unidadeId ? this.filter.unidadeId.toString() : '',
        clienteId: this.filter.clienteId ? this.filter.clienteId.toString() : '',
        empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : ''
      }
    });

    window.open(routeLink.href, '_blank');
  }
}
